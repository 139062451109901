import { EntityType, Family, ProgressState, StateService, User, UserCreateModel, UserRole, UserUpdateModel, config } from "@glideroggan/common";
import { FASTElement, Observable, css, customElement, html, observable, repeat, when } from "@microsoft/fast-element";
import { UserCard } from "../../components/user-card.component";
UserCard
import { Modal } from "@glideroggan/modal";
import { EditMemberModal } from "@glideroggan/modals/edit-member-modal";
import { ButtonState, ImageUploadedEvent } from "../../components/image-uploader.component";
Modal
import { ImageUploader } from "../../components/image-uploader.component";
ImageUploader

const template = html<FamilyAdmin>/*html*/`
<style>
  @import url('assets/css/base.css');
</style>
<modal-wrapper id="add-member">
    <add-member-modal></add-member-modal>
</modal-wrapper>
<modal-wrapper id="edit-member">
    <edit-member-modal></edit-member-modal>
</modal-wrapper>
<modal-wrapper id="delete-family">
    <confirmation-modal></confirmation-modal>
</modal-wrapper>
${when(x => x.ready, html<FamilyAdmin>/*html*/`
<div class="page">
    <h1>${x => x.family.name}</h1>
    <button class="button" @click="${x => x.openModal()}">add member</button>
    <div class="content">
        <div class="member-list">
            ${repeat(x => x.members, html<User>`<user-card :user="${x => x}"
                @click="${(x, e) => e.parent.editUser(x)}"></user-card>`)}
        </div>
        <div class="options">
            <form class="form-block">
                <div class="form-group option">
                    <label for="familyName">Family name</label>
                    <input type="text" class="form-input" id="familyName" name="familyName" 
                        value="${x => x.family.name}"/>
                </div>
                <div class="form-group option">
                    <input type="checkbox" class="form-checkbox-input" id="public" name="public" ?checked="${x => x.family.isPublic}"/>
                    <label for="public" class="form-checkbox-label" >Is the family public?</label>
                </div>
            </form>
            <img src="${x => x.family.imageUrl}" alt="family image">
            <div class="options-footer">
                <image-uploader state="${x => x.imageUploadState}" uploadProgress="0">Change image</image-uploader>
                <button class="button button-danger"
                    @click="${x => x.deleteFamilyConfirmModal()}">Delete family</button>
            </div>
        </div>
    </div>
</div>
`)}
`

const styles = css`
button {
    margin-top: 1em;
}
image-uploader {
    margin-top: 1em;
}
.options {
    display: grid;
    grid-template-rows: auto 1fr 40px;
    grid-template-columns: 350px;
    img {
        display: block;
        width: 350px;
        height: 250px;
        object-fit: cover;
    }
    .options-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.option {
    margin-bottom: 1em;
}
.page {
    color: var(--text-color);
    font-size: var(--base-font-size);
    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .member-list {
        display: grid;
        grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
        margin: 1em;
        
        user-card {
            cursor: pointer;
            width: 100%;
            // overflow: hidden;
        }
    }
}
`

export const imports = async (url: string) => await import(url)

@customElement({
    name: "family-admin",
    template,
    styles,
})
export class FamilyAdmin extends FASTElement {
    @observable ready: boolean = false
    @observable family: Family
    @observable members: User[]
    @observable imageUploadState: ButtonState = 'idle'
    addModal: Modal;
    editModal: Modal;
    imageUploadBtn: ImageUploader;
    deleteFamilyModal: Modal;
    constructor() {
        super();
        this.addEventListener('image-uploaded', (e) => this.onImageUploaded(e as CustomEvent<ImageUploadedEvent>))
        

    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        // get members of family
        this.members = await StateService.users.getUsers(this)
        this.family = await StateService.families.getFamily(this)
        // HACK: fix the image url based on where BE is hosted?
        // API endpoint is wrong here, as we still have the other things
        this.family.imageUrl = config.environment == 'server' ? `${config.apiEndpoint}${this.family.imageUrl}` : this.family.imageUrl

        this.deleteFamilyModal = this.shadowRoot!.querySelector('#delete-family') as Modal
        this.deleteFamilyModal.addEventListener('approve', this.onDeleteFamily.bind(this))
        this.deleteFamilyModal.addEventListener('cancel', this.onCloseDeleteFamilyModal.bind(this))
        this.addModal = this.shadowRoot!.querySelector('#add-member') as Modal
        this.addModal.addEventListener('add', this.addMemberSubmit.bind(this))
        this.editModal = this.shadowRoot!.querySelector('#edit-member') as Modal
        this.editModal.addEventListener('update', this.editMemberSubmit.bind(this))
        this.editModal.addEventListener('delete', this.deleteMember.bind(this))

        // listening on passkey-empty event, for deleting a users passkeys
        this.addEventListener('passkey-empty', this.onPasskeyEmpty.bind(this))

        this.ready = true
    }
    async onPasskeyEmpty(e: CustomEventInit<number>): Promise<void> {
        await StateService.users.deletePasskeys(this, e.detail as number)
    }
    async onCloseDeleteFamilyModal(e: Event) {
        this.deleteFamilyModal.hide()
    }
    async onDeleteFamily(e: Event) {
        await StateService.families.deleteFamily(this)
        this.deleteFamilyModal.hide()
        this.$emit('logout')
    }
    async onImageUploaded(e: CustomEvent<ImageUploadedEvent>): Promise<void> {
        console.debug('[Family-admin] - caught image-uploaded event')
        e.stopPropagation()
        // BUG: this event is catching the modal event for a user....
        // which kinda makes it obvious, that the imageuploader button in the modal, should be handled in the modal
        //
        this.imageUploadState = 'loading'
        const url = e.detail!.url
        // connect to BE and add another entity for images (Family)
        const progress = await StateService.uploadImage(this, e.detail.imageData, e.detail.filename, EntityType.Family, 1)
        let src = ''
        for await (const step of progress) {
            console.log('step', step)
            // button.setAttribute('uploadProgress', step.step.toString())
            if (step.state == ProgressState.success) {
                src = step.data
            }
        }
        this.family.imageUrl = url
        Observable.notify(this, 'family')
        this.imageUploadState = 'idle'
    }
    async deleteMember(e: CustomEventInit<number>): Promise<void> {
        const id = parseInt(e.detail as any)
        await StateService.users.deleteUser(this, id)
        this.editModal.hide()
        this.members = await StateService.users.getUsers(this)
    }
    async editUser(user: User) {
        await import('@glideroggan/modals/edit-member-modal')
        const el = this.editModal.querySelector('edit-member-modal') as EditMemberModal
        el.data = user
        console.log(el.data)
        this.editModal.show();
    }
    async editMemberSubmit(e: CustomEventInit<UserUpdateModel>): Promise<void> {
        const user = e.detail as UserUpdateModel
        console.log('editMemberSubmit', user)
        await StateService.users.updateUser(this, user.id, user)
        this.members = await StateService.users.getUsers(this)
        this.editModal.hide()
    }
    async addMemberSubmit(e: CustomEventInit<any>): Promise<void> {
        // TODO: image data should be in the event
        console.debug(e.detail)
        const user = e.detail!.data as UserCreateModel
        await StateService.families.addMember(this, this.family.id, user.firstname, user.lastname, user.role,
            user.password, user.email, user.imageUrl)
        this.members = await StateService.users.getUsers(this)
        const newUserId = this.members[this.members.length - 1].id
        if (user.imageUrl?.startsWith('blob:') || e.detail?.imageData) {
            // get the image data from the modal and call the image uploader
            // TODO: should we try to update the button? could we add a ref to it in the event?
            const imageData = e.detail.imageData
            const progress = await StateService.uploadImage(this, imageData.imageData, imageData.filename, EntityType.User, newUserId)

            for await (const step of progress) {
                // debug: we might want to update the actual button, but how
                console.log('step', step)
            }
        }
        this.members = await StateService.users.getUsers(this)
        this.addModal.hide()
    }
    async openModal(): Promise<void> {
        const AddMemberModal = await imports('@glideroggan/modals/add-member-modal')
        const el = this.addModal.querySelector('add-member-modal') as typeof AddMemberModal

        el.data = { ...el.data, firstname: '', lastname: this.family.name, role: UserRole.Child, password: '' }
        el.reset()
        console.debug('openModal', el)
        console.debug('openModal data', el.data)
        this.addModal.show();
    }
    async deleteFamilyConfirmModal(): Promise<void> {
        await import('@glideroggan/modals/confirmation-modal')
        const el = this.deleteFamilyModal.querySelector('confirmation-modal') as any
        el.question = 'Are you sure you want to delete this family?'
        el.confirmText = 'Delete'
        this.deleteFamilyModal.show();
    }

}